import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';

const About = () => (
        <Container>
            <Row className="justify-content-center mt-4">
                <Col>
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <h5 className="text-start">Qualifications:</h5>
                            <ul>
                                <li className="text-start">Lic. TCM (Licentiate in Traditional Chinese Medicine)</li>
                            </ul>
                            <h5 className="text-start">Main Areas of Practice:</h5>
                            <ul>
                                <li className="text-start">Acupuncture TCM</li>
                            </ul>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
;

export default About;