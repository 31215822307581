import {Container} from "react-bootstrap";
import logoImage from '../images/tcm-logo.svg';

const Header = () => (
    <header style={{backgroundColor: '#43ad94', width: '100%'}}>
        <Container className="d-flex align-items-center justify-content-start" style={{minHeight: '100px'}}>
            <img src={logoImage} alt="Logo" style={{width: '80px', height: '80px', marginRight: '24px'}}/>
            <div className="d-flex flex-column justify-content-center mt-2" style={{height: '100%'}}>
                <h1 className="text-start mb-0" style={{color: 'white', fontSize: '1.5rem'}}>Oxana Finn - TCM</h1>
                <p className="text-start mt-1" style={{color: 'white', fontSize: '1rem'}}>Providing Quality Traditional Chinese Medicine
                    Services</p>
            </div>
        </Container>
    </header>
);

export default Header;
