import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import profileImage from '../images/oksana-profile.jpg';

const DoctorProfile = () => (
        <Container>
            <Row className="justify-content-center mt-4">
                <Col>
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <h5 className="text-start">TCM Practitioner Profile</h5>
                            <p className="text-start">
                                Oxana Finn is a member of the PRTCM and recognised by VHI, Laya Healthcare and other main
                                Health Insurance providers in Ireland for the provision of Acupuncture treatments.</p>

                            <p className="text-start">She studied at the Irish College of Traditional Chinese Medicine
                                (ICTCM) in Dublin.</p>

                            <p className="text-start">Oxana is a teacher at the Irish College of Traditional
                                Chinese Medicine
                                (ICTCM).</p>
                            <p className="text-start">She is a qualified and insured practitioner of Acupuncture and
                                Traditional Chinese Medicine,
                                including:</p>
                            <ul>
                                <li className="text-start">Acupuncture</li>
                                <li className="text-start">Moxibustion</li>
                                <li className="text-start">Cupping</li>
                                <li className="text-start">Lifestyle and dietary therapy</li>
                            </ul>
                        </div>
                        <div className="flex-shrink-0">
                            <img src={profileImage} style={{width: '200px', height: 'auto', borderRadius: '8px'}}/>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
;

export default DoctorProfile;
