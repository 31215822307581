import {Col, Container, Row} from "react-bootstrap";
import React from "react";

const LocationMap = () => (
    <Container>
        <Row className="justify-content-center mt-3">
            <Col>
                <h4 className="text-start">Location</h4>
                <iframe
                    title="Practice Location"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2381.4208932441793!2d-6.229881923267983!3d53.35362197229141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48670ef9e5da6b4d%3A0xb34353b8daf80c15!2sProfessional%20Register%20of%20Traditional%20Chinese%20Medicine!5e0!3m2!1sen!2sie!4v1700413222217!5m2!1sen!2sie"
                    width="100%"
                    height="350"
                    style={{border: 0, borderRadius: '10px'}}
                    allowFullScreen=""
                    loading="lazy"
                ></iframe>
            </Col>
        </Row>
    </Container>);

export default LocationMap;