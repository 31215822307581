import React from 'react';
import Header from './Header';
import DoctorProfile from './DoctorProfile';
import LocationMap from './LocationMap';
import About from "./About";
import ContactDetails from "./ContactDetails";

const LandingPage = () => {
    return (
        <div>
            <Header/>
            <DoctorProfile/>
            <About/>
            <LocationMap/>
            <ContactDetails/>
        </div>
    );
};

export default LandingPage;