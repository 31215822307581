import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import emailIcon from '../images/email-icon.png';
import phoneIcon from '../images/phone-icon.png';

const ContactDetails = () => (
        <Container>
            <Row className="justify-content-center mt-5">
                <Col>
                    <div className="d-flex">
                        <div className="flex-grow-1 mb-5">
                            <h5 className="text-start">Contact Details</h5>
                            <div className="d-flex align-items-center mb-2 mt-4">
                                <img src={phoneIcon} alt="Phone" style={{width: '25px', marginRight: '16px'}}/>
                                <a style={{color: '#43ad94'}} className="text-start mb-0"
                                   href="tel:+35383047760">+35383047760</a>
                            </div>
                            <div className="d-flex align-items-center">
                                <img src={emailIcon} alt="Email" style={{width: '25px', marginRight: '16px'}}/>
                                <a style={{color: '#43ad94'}} className="text-start mb-0"
                                   href="mailto:oxana.tcm@gmail.com">oxana.tcm@gmail.com</a>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
;

export default ContactDetails;